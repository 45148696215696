<template>
  <b-modal ref="itemModal"
           :title="title"
           :ok-title="$t('save')"
           :cancel-title="$t('cancel')"
           @ok="save"
  >
    <div class="mb-3">
      <label>{{ $t("name") }}</label>
      <input type="text" v-model="item.name" class="form-control">
    </div>
    <div class="mb-3">
      <label>{{ $t("value") }}</label>
      <input type="text" v-model="item.value" class="form-control">
    </div>
    <div class="mb-3">
      <label>{{ $t("tag") }}</label>
      <v-select
        :options="tags"
        label="name"
        v-model="item.tags"
        :reduce="tag => tag.id"
        :multiple="true"
      ></v-select>
    </div>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "ItemModal",
  props: {
    groupId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      item: {}
    }
  },
  computed: {
    ...mapGetters("global", {
      "tags": "getTags"
    }),
    isEdit() {
      return typeof this.item.id == "undefined";
    },
    title() {
      return this.isEdit ? this.$t("createItem") : this.$t("editItem");
    }
  },
  methods: {
    ...mapActions("global", [
        "saveItem",
        "loadTags"
    ]),
    resetForm() {
      this.item = {
        name: null,
        value: null,
        tags: []
      }
    },
    prefillItem(item) {
      if(item == null) {
        this.resetForm();
      } else {
        this.item = {...item};
        let tags = [];

        this.item.tags.forEach(tag => {
          tags.push(tag.id);
        });

        this.item.tags = tags;
      }
    },
    open(item = null) {
      this.prefillItem(item);

      this.$refs['itemModal'].show();
    },
    close() {
      this.$refs['itemModal'].hide();
    },
    save(evt) {
      evt.preventDefault()

      let that = this;
      this.saveItem({groupId: this.groupId, item: this.item}).then(function(result) {
        if(result) {
          that.$emit("refreshGroup");
          that.resetForm();
          if(that.isEdit) {
            that.close();
          }
        }
      });
    }
  },
  created() {
    this.loadTags();
  }
}
</script>