import axios from "axios";
import store from "@/modules/store";
import Vue from 'vue';
import router from "@/modules/router";
import globalMixin from "@/mixins/globalMixin";

let domain = process.env.VUE_APP_SERVER_DOMAIN;
axios.defaults.baseURL = domain+"/api/v1";

axios.interceptors.request.use(
    config => {
        config.withCredentials = true;

        let jwtToken = store.getters["global/getJwtToken"];

        if(jwtToken) {
            config.headers['Authorization'] = `Bearer ${jwtToken}`;
        }
        return config;
    },
    error => {
        console.error(error);
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(error.response.status == 404) {
        router.push({name: "error-404"});
    } else {
        let errors = error.response.data;
        let errorsArr = [];

        if(typeof errors == "object") {
            Object.keys(errors).forEach(function (key) {
                let errorString = globalMixin.methods.formatErrorString(key + " " + errors[key]);
                errorsArr.push(errorString);
            })
        } else {
            let errorString = globalMixin.methods.formatErrorString(errors);
            errorsArr.push(errorString);
        }

        Vue.$toast.error(errorsArr.join("\n"));
    }
    return Promise.reject(error);
});