<template>

  <div>
    <font-awesome-icon icon="fa-solid fa-spinner" class="fa-spin"></font-awesome-icon>
    {{ $t("loading") }} ...
  </div>

</template>

<script>
export default {
  name: "LoadingSpinner"
}
</script>