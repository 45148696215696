import global_getters from "@/modules/stores/global/global_getters";
import global_actions from "@/modules/stores/global/global_actions";
import global_mutations from "@/modules/stores/global/global_mutations";
import group_actions from "@/modules/stores/group/group_actions";
import item_actions from "@/modules/stores/item/item_actions";
import tag_actions from "@/modules/stores/tag/tag_actions";

import { getField, updateField } from 'vuex-map-fields';

const state = () => {
    return {
        tags: [],
        groups: [],
        currency: "€",
        modulesLoading: []
    }
}

export default {
    namespaced: true,
    state: state,
    mutations: {...global_mutations, updateField},
    actions: {...global_actions, ...group_actions, ...item_actions, ...tag_actions},
    getters: {...global_getters, getField}
};
