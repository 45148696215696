import {mapActions} from "vuex";
import GroupBaseForm from "@/components/pages/groups/partials/GroupBaseForm.vue";

export default {
    components: {GroupBaseForm},
    data() {
        return {
            form: {
                id: 0,
                name: null
            }
        }
    },
    methods: {
        ...mapActions("global", [
            "saveGroup"
        ]),
        save() {
            let that = this;
            this.saveGroup({...this.form}).then(function (result) {
                if(result) {
                    that.$router.push({name: "show-group", params: {id: result.id}});
                }
            });
        }
    }
}