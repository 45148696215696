<template>

  <section>
    <div class="container">
      <page-header title="My lists">
        <router-link :to="{name: 'create-group'}" class="btn btn-success btn-sm float-right">{{ $t("create") }}</router-link>
      </page-header>

      <loading-spinner v-if="isModuleLoading('load-groups')"></loading-spinner>
      <div v-else>

        <div v-if="groups.length == 0"><i>{{ $t("noItemsInList") }}</i></div>

        <router-link v-for="group in groups" :key="group.id" tag="div" class="single-list-item" :to="{'name': 'show-group', params: {id: group.id}}">
          <div class="row">
            <div class="col-6">
              <div class="list-item-name">
                {{ group.name }}
              </div>
            </div>
            <div class="col-6 text-right">
            <span class="balance-status">
              <span class="value balance-color" :class="getBalanceClass(group.balance)">{{ group.balance | price }}</span>
              <span class="currency">{{ currency }}</span>
            </span>
            </div>
          </div>
        </router-link>

      </div>

    </div>
  </section>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "GroupsIndex",
  methods: {
    ...mapActions("global", [
        "loadGroups"
    ])
  },
  computed: {
    ...mapGetters("global", {
      "groups": "getGroups",
      "currency": "getCurrency"
    })
  },
  created() {
    this.loadGroups();
  }
}
</script>