import axios from "axios";
import apiConfig from "@/modules/api/partials/apiConfig";

export default {

    loadGroups(params, cb, errCb = null) {
        return axios.get(
            "groups/",
            params,
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    loadGroupItems(params, cb, errCb = null) {
        return axios.get(
            "groups/"+params.groupId+"/items/",
            { params: params.query},
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    loadGroup(id, cb, errCb = null) {
        return axios.get(
            "groups/" + id,
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    editGroup(id, cb, errCb = null) {
        return axios.get(
            "groups/" + id + "/edit",
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    deleteGroup(id, cb, errCb = null) {
        return axios.delete(
            "groups/" + id,
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    getGroupMonthlyIncomeExpenses(id, cb, errCb = null) {
        return axios.get(
            "groups/" + id + "/charts/monthly-income-expenses",
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    getGroupMonthlyBalance(id, cb, errCb = null) {
        return axios.get(
            "groups/" + id + "/charts/monthly-balance",
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    getGroupMonthlyBalancePerTag(id, cb, errCb = null) {
        return axios.get(
            "groups/" + id + "/charts/monthly-balance-per-tag",
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    saveGroup(params, cb, errCb = null) {
        let route = params.id == 0 ? "groups/" : "groups/" + params.id;
        let method = params.id == 0 ? "post" : "put";

        return axios({
            method: method,
            url: route,
            data: params,
            config: apiConfig.getConfig()
        }).then(cb).catch(errCb ? errCb : () => {});
    }

}