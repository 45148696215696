<template>

  <div class="alert bg-warning">
    {{ $t("noDataToDisplay") }} ...
  </div>

</template>

<script>
export default {
  name: "NoDataToDisplay"
}
</script>