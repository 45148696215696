<template>
  <div>
    <h4></h4>
    <div class="mb-3">
      <label>{{ $t("name") }}</label>
      <input type="text" :value="value.name" @input="update('name', $event.target.value)" class="form-control">
    </div>
    <button class="btn btn-primary" @click="$emit('submit')">{{ $t("save") }}</button>
  </div>
</template>

<script>
export default {
  name: "GroupBaseForm",
  props: ["value"],
  methods: {
    update(key, value) {
      this.$emit('input', { ...this.value, [key]: value })
    },
  },
}
</script>