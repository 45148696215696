<template>

  <section>
    <div class="container">
      <page-header title="Edit list">
        <router-link :to="{name: 'index-groups'}" class="btn btn-secondary btn-sm">{{ $t("backToList") }}</router-link>
      </page-header>

      <loading-spinner v-if="isModuleLoading('edit-group')"></loading-spinner>
      <group-base-form v-else @submit="save" v-model="form"></group-base-form>

    </div>
  </section>

</template>

<script>
import groupMixin from "@/components/pages/groups/mixins/groupMixin";
import {mapActions} from "vuex";

export default {
  name: "GroupsEdit",
  mixins: [groupMixin],
  methods: {
    ...mapActions("global", [
        "editGroup"
    ])
  },
  created() {
    let that = this;
    this.editGroup(this.$route.params.id).then(function (data) {
      that.form = data;
    });
  }
}
</script>