export default {

    setGroups(state, data) {
        state.groups = data;
    },

    setCurrency(state, data) {
        state.currency = data;
    },

    setTags(state, data) {
        state.tags = data;
    },

    startLoading(state, data) {
        if(!state.modulesLoading.includes(data)) {
            state.modulesLoading.push(data);
        }
    },

    stopLoading(state, data) {
        let index = state.modulesLoading.findIndex(i => i === data);
        state.modulesLoading.splice(index, 1);
    }

};