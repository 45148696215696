export default {

    getConfig() {
        let config = {
            headers: {
                "Content-Type": "application/json"
            }
        };

        return config;
    },
}