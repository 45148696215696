import globalStore from "@/modules/stores/global/global_store";
import authenticationStore from "@/modules/stores/authentication/authentication_store";
import Vuex from "vuex"
import Vue from "vue"

Vue.use(Vuex)
export default new Vuex.Store({
    modules: {
        "global": globalStore,
        "authentication": authenticationStore
    }
})