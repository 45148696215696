<template>

  <section>
    <div class="container">
      <h3>{{ $t("welcome") }} {{ user.name }}</h3>
    </div>
  </section>

</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "DashboardPage",
  computed: {
    ...mapGetters("authentication", {
      "user": "getUser"
    })
  }
}
</script>