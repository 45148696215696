<template>
  <div>
    <h4 class="mb-3">
      {{ title }}
      <div class="float-right">
        <slot></slot>
      </div>
    </h4>
    <hr>
  </div>
</template>

<script>
export default {
  props: [
      "title"
  ],
  name: "PageHeader"
}
</script>