import authentication_getters from "@/modules/stores/authentication/authentication_getters";
import authentication_actions from "@/modules/stores/authentication/authentication_actions";
import authentication_mutations from "@/modules/stores/authentication/authentication_mutations";

const state = () => {
    return {
        reAuthenticationTried: false,
        user: null
    }
}

export default {
    namespaced: true,
    state: state,
    mutations: authentication_mutations,
    actions: authentication_actions,
    getters: authentication_getters
};
