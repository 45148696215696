<template>
  <section>
    <div class="container">
      <h3>404</h3>
      <p>Page not found</p>
    </div>
  </section>
</template>

<script>
export default {
  name: "NotFoundError"
}
</script>