<template>
  <b-modal ref="tagModal"
           :title="title"
           :ok-title="$t('save')"
           :cancel-title="$t('cancel')"
           @ok="save"
  >
    <div class="mb-3">
      <label>{{ $t("name") }}</label>
      <input type="text" v-model="tag.name" class="form-control">
    </div>
  </b-modal>
</template>

<script>
import {BModal} from "bootstrap-vue";
import {mapActions} from "vuex";

export default {
  name: "TagModal",
  data() {
    return {
      tag: {}
    }
  },
  computed: {
    isEdit() {
      return typeof this.tag.id == "undefined";
    },
    title() {
      return this.isEdit ? this.$t("createTag") : this.$t("editTag");
    }
  },
  methods: {
    ...mapActions("global", [
        "saveTag"
    ]),
    resetForm() {
      this.tag = {
        name: null
      }
    },
    open(tag = null) {
      if(tag == null) {
        this.resetForm();
      } else {
        this.tag = {...tag};
      }

      this.$refs['tagModal'].show();
    },
    close() {
      this.$refs['tagModal'].hide();
    },
    save(evt) {
      evt.preventDefault()

      let that = this;
      this.saveTag(this.tag).then(function(result) {
        if(result) {
          that.$emit("refreshTags");
          that.resetForm();
          if(that.isEdit) {
            that.close();
          }
        }
      });
    }
  }
}
</script>