import groupApi from "@/modules/api/groupApi";
import Vue from "vue";
import i18n from "@/modules/i18n";

export default {

    loadGroups: function ({commit}, params) {
        commit("startLoading", "load-groups");
        groupApi.loadGroups(params, function (data) {
            commit("setGroups", data.data);
            commit("stopLoading", "load-groups");
        });
    },

    loadGroupItems: function({commit}, params) {
        commit("startLoading", "load-group-items");
        return groupApi.loadGroupItems(params, function (data) {
            commit("stopLoading", "load-group-items");
            return data.data;
        });
    },

    loadGroup: function ({commit}, id) {
        commit("startLoading", "load-group");
        return groupApi.loadGroup(id, function (data) {
            commit("stopLoading", "load-group");
            return data.data;
        });
    },

    editGroup: function ({commit}, id) {
        commit("startLoading", "edit-group");
        return groupApi.editGroup(id, function (data) {
            commit("stopLoading", "edit-group");
            return data.data;
        });
    },

    deleteGroup: function ({commit}, id) {
        commit("startLoading", "delete-group");
        return groupApi.deleteGroup(id, function (data) {
            commit("stopLoading", "delete-group");
            Vue.$toast.error(i18n.t("groupDeleted"));
            return true;
        });
    },

    getGroupMonthlyIncomeExpenses: function ({commit}, id) {
        commit("startLoading", "monthly-income-expenses");
        return groupApi.getGroupMonthlyIncomeExpenses(id, function (data) {
            return data.data;
        });
    },

    getGroupMonthlyBalance: function ({commit}, id) {
        commit("startLoading", "monthly-balance");
        return groupApi.getGroupMonthlyBalance(id, function (data) {
            return data.data;
        });
    },

    getGroupMonthlyBalancePerTag: function ({commit}, id) {
        commit("startLoading", "monthly-balance-per-tag");
        return groupApi.getGroupMonthlyBalancePerTag(id, function (data) {
            return data.data;
        });
    },

    saveGroup({commit}, group) {
        commit("startLoading", "save-group");
        return groupApi.saveGroup(group, function (data) {
            commit("stopLoading", "save-group");
            Vue.$toast.success(i18n.t(group.id == 0 ? "groupCreated" : "groupUpdated"));
            return data.data;
        }, function (){
            return false;
        });
    }

};