<template>

  <section>
    <div class="container">
      <div v-if="isLoading">
        <i class="fa fa-spinner fa-spin"></i>
      </div>
      <div v-else>
        <page-header :title="group.name">
          <router-link :to="{name: 'index-groups'}" class="btn btn-secondary btn-sm mr-3">{{ $t("backToList") }}</router-link>
          <router-link :to="{name: 'edit-group', params: {id: group.id}}" class="btn btn-primary btn-sm mr-3">{{ $t("edit") }}</router-link>
          <button class="btn btn-sm btn-danger" @click="confirmDeleteGroup">{{ $t("delete") }}</button>
        </page-header>

        <loading-spinner v-if="isModuleLoading('load-group-items')"></loading-spinner>
        <div v-show="!isModuleLoading('load-group-items')">
          <group-items-table
            v-if="groupItems"
            :group="group"
            @openModal="openModal"
            @refreshGroupItems="refreshGroupItems($event)"
            :group-items="groupItems"
          ></group-items-table>
        </div>
        <item-modal @refreshGroup="refreshGroup" ref="itemModal" :group-id="group.id"></item-modal>

        <group-charts ref="groupCharts" :group-id="group.id"></group-charts>
      </div>
    </div>

  </section>

</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ItemModal from "@/components/modules/items/ItemModal.vue";
import GroupItemsTable from "@/components/pages/groups/partials/GroupItemsTable.vue"
import GroupCharts from "@/components/pages/groups/partials/GroupCharts.vue";

export default {
  name: "GroupsShow",
  components: {GroupCharts, ItemModal, GroupItemsTable},
  data() {
    return {
      isLoading: true,
      group: {},
      wasInitialized: false,
      groupItems: null
    }
  },
  methods: {
    ...mapActions("global", [
        "loadGroup",
        "deleteGroup",
        "loadGroupItems"
    ]),
    refreshGroupItems(query = null) {
      let that = this;
      this.loadGroupItems({groupId: this.group.id, query: query}).then(function(data) {
        that.groupItems = data;
        if(!that.wasInitialized || query == null) {
          that.$refs["groupCharts"].loadCharts();
        }
        that.wasInitialized = true;
      });
    },
    confirmDeleteGroup() {
      let that = this;
      this.confirmationDialog({}, function () {
        that.deleteGroup(that.group.id).then(function (result) {
          if(result) {
            that.$router.push({name: "index-groups"});
          }
        });
      });
    },
    openModal(item = null) {
      this.$refs['itemModal'].open(item);
    },
    refreshGroup() {
      let that = this;
      this.loadGroup(this.$route.params.id).then(function (data) {
        that.group = data;
        that.isLoading = false;
        that.refreshGroupItems();
      });
    }
  },
  created() {
    this.refreshGroup();
  }
}
</script>