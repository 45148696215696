import axios from "axios";
import apiConfig from "@/modules/api/partials/apiConfig";

export default {

    saveItem(groupId, params, cb, errCb = null) {
        let isStoreRequest = typeof params.id == "undefined";

        let route = ("groups/" + groupId) + (isStoreRequest ? "/items" : "/items/" + params.id);
        let method = isStoreRequest ? "post" : "put";

        return axios({
            method: method,
            url: route,
            data: params,
            config: apiConfig.getConfig()
        }).then(cb).catch(errCb ? errCb : () => {});
    },

    deleteItem(groupId, itemId, cb, errCb = null) {
        let route = ("groups/" + groupId + "/items/" + itemId);

        return axios({
            method: "delete",
            url: route,
            config: apiConfig.getConfig()
        }).then(cb).catch(errCb ? errCb : () => {});
    }

}