<template>
    <div>
      <h5>{{ $t("search") }}</h5>
      <div class="row">
        <div class="col-md-3 pr-md-0">
          <input v-model="itemsSearchForm.name" type="text" class="form-control form-control-sm" :placeholder="$t('searchedPhrase')">
        </div>
        <div class="col-md-4 pr-md-0">
          <div class="row">
            <div class="col-md-6 pr-md-0">
              <input v-model="itemsSearchForm.value_from" type="number" step="0.01" class="form-control form-control-sm" :placeholder="$t('price_from')">
            </div>
            <div class="col-md-6">
              <input v-model="itemsSearchForm.value_to" type="number" step="0.01" class="form-control form-control-sm" :placeholder="$t('price_from')">
            </div>
          </div>
        </div>
        <div class="col-md-2 pr-md-0">
          <v-select
            :options="tags"
            :reduce="tag => tag.id"
            label="name"
            class="v-select-sm"
            :multiple="true"
            placeholder="Tag"
            v-model="itemsSearchForm.tags"
          ></v-select>
        </div>
        <div class="col-md-3">
          <button class="btn btn-sm btn-primary mr-3" @click="refreshItems()">{{ $t('search') }}</button>
          <button class="btn btn-sm btn-secondary" @click="clearSearch()">{{ $t('clear') }}</button>
        </div>
      </div>

      <hr>

      <table class="table no-top-border">
          <colgroup>
            <col width="50%">
            <col width="10%">
            <col width="10%">
            <col width="30%">
          </colgroup>
          <thead>
          <tr>
            <th>{{ $t("name") }}</th>
            <th>{{ $t("value") }}</th>
            <th>{{ $t("date") }}</th>
            <th class="text-right">
              <button class="btn btn-success btn-sm" @click="$emit('openModal')">{{ $t("add") }}</button>
            </th>
          </tr>
          </thead>
          <tbody>
            <tr v-if="groupItems.items.length == 0">
              <td colspan="2">
                <i>{{ $t("noItemsInList") }}</i>
              </td>
            </tr>
            <tr v-for="item in groupItems.items" :key="item.id">
              <td>
                {{ item.name }} 
                <div v-if="item.tags.length > 0">
                  <span v-for="tag in item.tags" :key="tag.id + '_' + item.id" class="badge mr-1 badge-secondary">{{ tag.name }}</span>
                </div>
              </td>
              <td class="balance-color" :class="getBalanceClass(item.value)">
                  {{ item.value }} {{ currency }}
              </td>
              <td>{{ formatDate(item.createdAt) }}</td>
              <td>
                <button class="btn btn-primary btn-sm mr-3" @click="$emit('openModal', item)">{{ $t('edit') }}</button>
                <button class="btn btn-danger btn-sm" @click="confirmDeleteItem(item.id)">{{ $t('delete') }}</button>
              </td>
            </tr>
          </tbody>
          <tfoot>
          <tr>
            <td><i>{{ $t("allItemsTotal") }}:</i> </td>
            <td class="balance-color" :class="getBalanceClass(groupItems.balance)">{{ groupItems.balance }} {{ currency }}</td>
            <td></td>
            <td></td>
          </tr>
          </tfoot>
        </table>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import { mapFields } from 'vuex-map-fields';

export default {
    emits: ['openModal', 'refreshGroupItems'],
    props: {
      groupItems: {
        type: Object,
        required: true
      },
      group: {
        type: Object,
        required: true
      },
    },
    data() {
        return {
            itemsSearchForm: {}
        }
    },
    name: "GroupItemsTable",
    computed: {
        ...mapGetters("global", {
          "currency": "getCurrency",
          "tags": "getTags"
        })
    },
    methods: {
        ...mapActions("global", [
            "loadGroup",
            "deleteGroup",
            "deleteItem"
        ]),
        resetSearchForm() {
          this.itemsSearchForm = {
            name: null,
            value_from: null,
            value_to: null,
            tags: []
          };
        },
        refreshItems() {
            this.$emit("refreshGroupItems", this.itemsSearchForm);
        },
        clearSearch() {
          this.resetSearchForm()
          this.refreshItems()
        },
        confirmDeleteItem(itemId) {
            let that = this;
            this.confirmationDialog({}, function () {
                that.deleteItem({groupId: that.group.id, itemId: itemId}).then(function (result) {
                    that.$emit("refreshGroupItems");
                });
            });
        },
    },
  created() {
      this.resetSearchForm()
  }
}
</script>