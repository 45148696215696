import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueRouter from 'vue-router'
import router from "@/modules/router";
import store from "@/modules/store"
import Toast from "vue-toastification";
import globalMixin from "@/mixins/globalMixin";
import LoadingSpinner from "@/components/partials/LoadingSpinner.vue";
import NoDataToDisplay from "@/components/partials/NoDataToDisplay.vue";
import PageHeader from "@/components/partials/PageHeader.vue";
import VueSweetalert2 from 'vue-sweetalert2';
import VueMeta from "vue-meta";
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import i18n from "@/modules/i18n";
import VueSelect from 'vue-select';

import "@/modules/fontawesome";

import 'bootstrap/dist/css/bootstrap.css'
import '@/assets/global.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import "@/modules/axios";
import "vue-toastification/dist/index.css"
import "vue-select/dist/vue-select.css";
import 'sweetalert2/dist/sweetalert2.min.css';

Vue.use(Toast)
Vue.use(VueMeta)
Vue.use(VueSweetalert2)
Vue.use(VueRouter)
Vue.use(BootstrapVue)
Vue.component("v-select", VueSelect)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.filter('price', function (value) {
  return value.toFixed(2);
});

Vue.component("loading-spinner", LoadingSpinner)
Vue.component("no-data", NoDataToDisplay)
Vue.component("page-header", PageHeader)

Vue.mixin(globalMixin)

Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')