import Vue from "vue";
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

const i18n = new VueI18n({
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
        en: {
            name: 'Name',
            value: "Value",
            date: "Date",
            add: "Add",
            search: "Search",
            price_from: "Price from",
            price_to: "Price to",
            edit: "Edit",
            delete: "Delete",
            clear: "Clear",
            noItemsInList: "This list does not contain any items ...",
            allItemsTotal: "All items total",
            backToList: "Back to list",
            cancel: "Cancel",
            save: "Save",
            create: "Create",
            loading: "Loading",
            balancePerMonth: "Balance per month",
            welcome: "Welcome",
            groupCreated: "Group was created",
            groupUpdated: "Group was updated",
            groupDeleted: "Group was deleted",
            itemCreated: "Item was created",
            itemUpdated: "Item was updated",
            itemDeleted: "Item was deleted",
            tagCreated: "Tag was created",
            tagUpdated: "Tag was updated",
            tagDeleted: "Tag was deleted",
            confirmItemDeletion: "Are you sure you want to delete this item?",
            confirmation: "Confirmation",
            yes: "Yes",
            no: "No",
            email: "Email",
            logout: "Logout",
            myLists: "My lists",
            myTags: "My tags",
            password: "Password",
            login: "Login",
            monthlyIncomeExpenses: "Monthly income/expenses",
            authenticated: "Successfully authenticated",
            loggedOut: "Successfully logged out",
            createTag: "Create tag",
            editTag: "Edit tag",
            createItem: "Create item",
            editItem: "Edit item",
            tag: "Tag",
            searchedPhrase: "Searched phrase ...",
            balancePerTagPerMonth: "Monthly balance per tag",
            noDataToDisplay: "No data to display ..."
        }
    },
});

export default i18n;