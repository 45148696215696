import axios from "axios";
import apiConfig from "@/modules/api/partials/apiConfig";

export default {

    login(params, cb, errCb = null) {
        return axios.post(
            "auth/authenticate",
            params,
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    logout(cb, errCb = null) {
        return axios.post(
            "auth/logout",
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    getAuthenticatedUser(cb, errCb = null) {
        return axios.post(
            "auth/user",
            {},
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    },

    refresh(cb, errCb = null) {
        return axios.post(
            "auth/refresh",
            {},
            apiConfig.getConfig()
        ).then(cb).catch(errCb ? errCb : () => {});
    }

}