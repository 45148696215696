<template>
  <div id="app">
    <main-navbar></main-navbar>

    <div id="content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

import MainNavbar from "@/components/partials/MainNavbar.vue";

export default {
  name: 'App',
  components: {
    MainNavbar
  }
}
</script>

<style>
</style>
