import VueRouter from "vue-router";
import Vue from "vue";
import AuthPage from "@/components/pages/auth/AuthPage.vue";
import DashboardPage from "@/components/pages/dashboard/DashboardPage.vue";
import GroupsCreate from "@/components/pages/groups/GroupsCreate.vue";
import GroupsShow from "@/components/pages/groups/GroupsShow.vue";
import GroupsIndex from "@/components/pages/groups/GroupsIndex.vue";
import TagsIndex from "@/components/pages/tags/TagsIndex.vue";
import store from "@/modules/store";
import GroupsEdit from "@/components/pages/groups/GroupsEdit.vue";
import NotFoundError from "@/components/pages/errors/NotFoundError.vue";

const routes = [
    { path: '/', name: "dashboard", component: DashboardPage },
    { path: '/login', name: "login", component: AuthPage, meta: { nonAuth: true }},

    { path: '/groups', name: "index-groups", component: GroupsIndex },
    { path: '/groups/create', name: "create-group", component: GroupsCreate},
    { path: '/groups/edit/:id', name: "edit-group", component: GroupsEdit},
    { path: '/groups/:id', name: "show-group", component: GroupsShow},

    { path: '/tags', name: "index-tags", component: TagsIndex },

    { path: "*", name: "error-404", component: NotFoundError }
];

const router = new VueRouter({
    routes: routes,
    mode: "history"
});

router.beforeEach((to, from, next) => {
    // if not yet tried, try to get the user
    if (!store.getters["authentication/getReAuthenticationTried"] && !store.getters["authentication/isAuthenticated"]) {
        let intended = to.fullPath;
        store.dispatch("authentication/getAuthenticatedUser").then(function (data) {
            if(data) {
                if(to.name == "login") {
                    next("/")
                } else {
                    next(intended);
                }
            } else {
                if(to.matched.some(record => record.meta.nonAuth)) {
                    next()
                } else {
                    next({ name: 'login' });
                }
            }
        });
    } else {
        // if the route does not require auth or the user is authenticated, allow his action
        if(to.matched.some(record => record.meta.nonAuth) || store.getters["authentication/isAuthenticated"]) {
            next();
        } else {
            next({ name: 'login' });
        }
    }
})

export default router;