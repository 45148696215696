import {mapActions, mapGetters, mapMutations} from "vuex";
import moment from "moment";

export default {
    metaInfo: {
        title: "Budgeteer",
    },
    computed: {
        ...mapGetters("global", {
            "modulesLoading": "getModulesLoading"
        }),
    },
    methods: {
        ...mapMutations("global", {
            "startLoading": "startLoading",
            "stopLoading": "stopLoading"
        }),
        formatErrorString(text) {
            if(typeof text == "string" && text.length > 1) {
                text = text[0].toUpperCase() + text.substring(1);
            }

            return text;
        },
        isUndefined(value) {
            return typeof value == "undefined";
        },
        formatCurrency(value) {
            return value + " €";
        },
        formatDate(date) {
          return moment(date).format("DD.MM.YYYY");
        },
        getBalanceClass(value) {
            if(value == 0) {
                return "neutral";
            } else if(value < 0) {
                return "negative";
            } else {
                return "positive";
            }
        },
        isModuleLoading(module) {
            return this.modulesLoading.includes(module);
        },
        // redirect
        throwError(code) {
            this.$router.push({name: "error-" + code})
        },
        // if no data is present, throws a 404 error
        dataOrNotFound(data) {
            if(!data) {
                this.throwError(404);
            }

            return data;
        },
        confirmationDialog(data = {}, trueCallback = () => {}, falseCallback = () => {}) {

            let title = typeof data.title == "undefined" ? this.$t("confirmation") : data.title;
            let text = typeof data.text == "undefined" ? this.$t("confirmItemDeletion") : data.text;

            this.$swal({
                title: title,
                text: text,
                showConfirmButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                showCancelButton: true,
                buttonsStyling: false,
                customClass: {
                    confirmButton: 'btn btn-primary mr-3',
                    cancelButton: 'btn btn-danger'
                },
            }).then(function (result) {
                if(result.value) {
                    trueCallback();
                } else {
                    falseCallback();
                }
            });
        }
    }
}