<template>
  <div>
    <div class="row">
      <div class="col-md-12 mt-3">
        <h5>{{ $t("monthlyIncomeExpenses") }}</h5>
        <loading-spinner v-if="isModuleLoading('monthly-income-expenses')"></loading-spinner>
        <no-data v-else-if="isDatasetEmpty(monthlyIncomeExpenses)"></no-data>
        <div v-else>
          <Bar
            chart-id="income-expenses"
            :chart-options="balancePerMonthOptions"
            :chart-data="monthlyIncomeExpenses"
          />
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <h5>{{ $t("balancePerMonth") }}</h5>
        <loading-spinner v-if="isModuleLoading('monthly-balance')"></loading-spinner>
        <no-data v-else-if="isDatasetEmpty(monthlyBalance)"></no-data>
        <div v-else>
          <LineChartGenerator
            chart-id="monthly-balance"
            :chart-options="balancePerMonthOptions"
            :chart-data="monthlyBalance"
          />
        </div>
      </div>
      <div class="col-md-12 mt-3">
        <h5>{{ $t("balancePerTagPerMonth") }}</h5>
        <loading-spinner v-if="isModuleLoading('monthly-balance-per-tag')"></loading-spinner>
        <no-data v-else-if="isDatasetEmpty(monthlyBalancePerTag)"></no-data>
        <div v-else>
          <Bar
            chart-id="monthly-balance-per-tag"
            :chart-options="balancePerMonthOptions"
            :chart-data="monthlyBalancePerTag"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Bar, Line as LineChartGenerator } from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  PointElement,
  LinearScale
} from 'chart.js'
import {mapActions} from "vuex";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, LineElement, PointElement)

export default {
  name: "GroupCharts",
  components: {Bar, LineChartGenerator},
  props: [
    "groupId"
  ],
  data() {
    return {
      monthlyBalance: {},
      monthlyIncomeExpenses: {},
      monthlyBalancePerTag: {}
    }
  },
  watch: {
    groupItems() {
      this.load
    }
  },
  computed: {
    balancePerMonthOptions() {
      let that = this;
      return {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            grid: {
              offset: true
            },
            offset: true,
          },
          y: {
            ticks: {
              callback: that.formatCurrency
            }
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: function(context) {
                return that.formatCurrency(context.dataset.data[context.dataIndex]);
              }
            }
          }
        }
      };
    }
  },
  methods: {
    ...mapActions("global", [
      "getGroupMonthlyIncomeExpenses",
      "getGroupMonthlyBalance",
      "getGroupMonthlyBalancePerTag"
    ]),
    isDatasetEmpty(dataset) {
      if(typeof dataset.datasets == "undefined" || dataset.datasets.length == 0) {
        return true;
      } else {
        let isEmpty = true;

        dataset.datasets.forEach(element => {
          if(element.data.length > 0) {
            isEmpty = false;
          }
        });

        return isEmpty;
      }
    },
    loadCharts() {
      let that = this;

      this.getGroupMonthlyIncomeExpenses(this.groupId).then(function (data) {
        if(!that.isUndefined(data)) {
          that.monthlyIncomeExpenses = data;
          that.stopLoading("monthly-income-expenses");
        }
      });

      this.getGroupMonthlyBalance(this.groupId).then(function (data) {
        if(!that.isUndefined(data)) {
          that.monthlyBalance = data;
          that.stopLoading("monthly-balance");
        }
      });

      this.getGroupMonthlyBalancePerTag(this.groupId).then(function (data) {
        if(!that.isUndefined(data)) {
          that.monthlyBalancePerTag = data;
          that.stopLoading("monthly-balance-per-tag");
        }
      });
    }
  },
  created() {
  }
}
</script>