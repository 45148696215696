import itemApi from "@/modules/api/itemApi";
import Vue from "vue";
import i18n from "@/modules/i18n";

export default {

    saveItem({commit}, data) {
        commit("startLoading", "save-item");
        return itemApi.saveItem(data.groupId, data.item, function (response) {
            commit("stopLoading", "save-item");
            Vue.$toast.success(i18n.t(typeof data.id == "undefined" ? "itemCreated" : "itemUpdated"));
            return response.data;
        }, function (err){
            return false;
        });
    },

    deleteItem({commit}, data) {
        commit("startLoading", "delete-item");
        return itemApi.deleteItem(data.groupId, data.itemId, function (data) {
            commit("stopLoading", "delete-item");
            Vue.$toast.error(i18n.t("itemDeleted"));
            return true;
        }, function (err){
            return false;
        });
    }

};