import globalApi from "@/modules/api/globalApi";
import router from "@/modules/router";
import Vue from "vue";
import store from "@/modules/store";
import i18n from "@/modules/i18n";

export default {

    login: function ({commit}, params) {
        globalApi.login(params, function (data) {
            commit("setUser", data.data)
            Vue.$toast.success(i18n.t("authenticated"));
            router.push("/");
        });
    },

    logout: function ({commit}, params) {
        globalApi.logout(function (data) {
            commit("setUser", null)
            Vue.$toast.success(i18n.t("loggedOut"));
            router.push("/login");
        })
    },

    refresh: function () {
        globalApi.refresh( function () {
            router.push("/");
        });
    },

    getAuthenticatedUser: function ({commit}) {
        return globalApi.getAuthenticatedUser(function (data) {
            store.commit("authentication/setReAuthenticationTried", true);

            if(data.data != null && data.data.email != null) {
                commit("setUser", data.data);
                return true;
            }

            return false;
        }, function () {
            return false;
        });
    },

};