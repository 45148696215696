<template>

  <b-navbar id="mainNavbarComponent" toggleable="sm" type="dark" variant="info">
    <b-navbar-brand href="/" :to="{name: 'dashboard'}">Budgeteer</b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="isAuthenticated">
        <b-nav-item href="/lists" :to="{name: 'index-groups'}">{{ $t("myLists") }}</b-nav-item>
        <b-nav-item href="/tags" :to="{name: 'index-tags'}">{{ $t("myTags") }}</b-nav-item>
        <b-nav-item @click="logout()" right>{{ $t("logout") }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-else>
        <b-nav-item href="/login" :to="{name: 'login'}">{{ $t("login") }}</b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "MainNavbar",
  computed: {
    ...mapGetters("authentication", {
      "isAuthenticated": "isAuthenticated"
    })
  },
  methods: {
    ...mapActions("authentication", [
      "logout"
    ])
  }
}
</script>

