<template>

  <section>
    <div class="container">
      <div class="mb-3">
        <label>{{ $t("email") }}</label>
        <input type="email" v-model="form.email" class="form-control">
      </div>
      <div class="mb-3">
        <label>{{ $t("password") }}</label>
        <input type="password" v-model="form.password" class="form-control">
      </div>
      <button class="btn btn-primary" @click="submitForm">{{ $t("login") }}</button>
    </div>
  </section>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AuthPage",
  data() {
    return {
      form: {
        email: null,
        password: null
      }
    }
  },
  computed: {
    ...mapGetters("authentication", {
      "authenticatedUser": "getUser"
    })
  },
  methods: {
    ...mapActions("authentication", [
        "login",
        "refresh",
        "getAuthenticatedUser"
    ]),
    submitForm() {
      this.login(this.form);
    }
  },
  created() {
  }
}
</script>