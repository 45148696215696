export default {

    setUser(state, data) {
        state.user = data;
    },

    setReAuthenticationTried(state, data) {
        state.reAuthenticationTried = data;
    }

};