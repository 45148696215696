<template>

  <section>
    <div class="container">
      <page-header title="Create list">
        <router-link :to="{name: 'index-groups'}" class="btn btn-secondary btn-sm">{{ $t("backToList") }}</router-link>
      </page-header>
      <group-base-form v-model="form" @submit="save"></group-base-form>
    </div>
  </section>

</template>

<script>
import groupMixin from "@/components/pages/groups/mixins/groupMixin";

export default {
  name: "GroupsCreate",
  mixins: [groupMixin]
}
</script>