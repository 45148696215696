export default {

    getUser: state => {
        return state.user;
    },

    getGroups: state => {
        return state.groups;
    },

    getCurrency: state => {
        return state.currency;
    },

    getTags: state => {
        return state.tags;
    },

    getModulesLoading: state => {
        return state.modulesLoading;
    }

};