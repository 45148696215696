<template>

    <section>
      <div class="container">
        <page-header title="My tags">
          <button @click="openModal()" class="btn btn-success btn-sm float-right">{{ $t("create") }}</button>
        </page-header>
  
        <loading-spinner v-if="isModuleLoading('load-tags')"></loading-spinner>
        <div v-else>
          <table class="table no-top-border">
            <thead>
            <tr>
              <th>{{ $t("name") }}</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              <tr v-if="tags.length == 0">
                <td colspan="2">
                  <i>{{ $t("noItemsInList") }}</i>
                </td>
              </tr>
              <tr v-for="tag in tags" :key="tag.id">
                <td>{{ tag.name }}</td>
                <td>
                  <button class="btn btn-primary btn-sm mr-3" @click="openModal(tag)">{{ $t('edit') }}</button>
                  <button class="btn btn-danger btn-sm" @click="confirmDeleteTag(tag.id)">{{ $t('delete') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <tag-modal @refreshTags="refreshTags" ref="tagModal"></tag-modal>
  
      </div>
    </section>
  
  </template>
  
  <script>
  import {mapActions, mapGetters} from "vuex";
  import TagModal from "@/components/modules/tags/TagModal"
  
  export default {
    name: "TagsIndex",
    components: {
      TagModal
    },
    computed: {
      ...mapGetters("global", {
        "tags": "getTags"
      })
    },
    methods: {
      ...mapActions("global", [
          "loadTags",
          "deleteTag"
      ]),
      openModal(tag = null) {
        this.$refs['tagModal'].open(tag);
      },
      confirmDeleteTag(tagId) {
          let that = this;
          this.confirmationDialog({}, function () {
              that.deleteTag(tagId).then(function (result) {
                  that.refreshTags();
              });
          });
      },
      refreshTags() {
        let that = this;
        this.loadTags();
      }
    },
    created() {
      this.refreshTags();
    }
  }
  </script>