import tagApi from "@/modules/api/tagApi";
import Vue from "vue";
import i18n from "@/modules/i18n";

export default {

    loadTags({commit}) {
        commit("startLoading", "load-tag");
        return tagApi.loadTags(function (data) {
            commit("setTags", data.data);
            commit("stopLoading", "load-tag");
            return true;
        }, function (err){
            return false;
        });
    },

    saveTag({commit}, data) {
        commit("startLoading", "save-tag");
        return tagApi.saveTag(data, function (response) {
            commit("stopLoading", "save-tag");
            Vue.$toast.success(i18n.t(typeof data.id == "undefined" ? "tagCreated" : "tagUpdated"));
            return response.data;
        }, function (err){
            return false;
        });
    },

    deleteTag({commit}, tagId) {
        commit("startLoading", "delete-tag");
        return tagApi.deleteTag(tagId, function (data) {
            commit("stopLoading", "delete-tag");
            Vue.$toast.error(i18n.t("tagDeleted"));
            return data.data;
        }, function (err){
            return false;
        });
    }

};